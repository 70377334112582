@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/berlin-sans-fb');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;


  --green-kati: #6AB50C;
  --blue-kati: #1554a4;
  --blue-kati-dark: #004197;
  --light-gray: #fafafa;
  --section-padding: 1em 3em;
  --section-sm-padding: 1em;

  --bs-btn-active-bg: var(--green-kati) !important;
  --bs-btn-active-border-color: var(--green-kati) !important;
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  font-family: Montserrat, sans-serif;
}

h1,h2,h3,h4,h5 {
  font-family: "Roboto Condensed", sans-serif;
}

#logo, .logo {
  width: 50px;
  height: 50px;
}
button {
  cursor:pointer;
}
.p10 {
  padding-left: 10px;
}
.btn-fit {
  width: fit-content;
}
.cta_block {
  background: var(--green-kati);
  color: white;
  border-radius: 10px;
  padding: 10px;
  border: 0;
  outline: 0;
}
.btn_cta {
  background: var(--green-kati);
  border: 0;
  outline: 0;
  padding: 10px;
  color: white;
  border-radius: 10px;
  width: 100%;
}
.btn_cta:hover {
  background: white;
  border: 0;
  outline: 0;
  padding: 10px;
  color: var(--green-kati);
  border-radius: 10px;
  width: 100%;
}
.btn-success {
  background: var(--blue-kati);
  border: 0;
}
.cta_button_blue {
  background: var(--blue-kati);
  color: white;
  border: 1px solid white;
}
.btn_lightyellow {
  background: lightgoldenrodyellow;
  color: darkgrey;
  border: 1px solid darkgrey;
}
.text-error {
  color: red;
  display: block;
  margin: 0;
  padding: 2px;
}
.text-link {
  color: var(--green-kati);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}
.inline_text {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.inline_text_start {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: stretch;
  align-items: center;
}
.inline_text > *{
  flex-shrink: 0;
}
.hr_lightgray {
  border:0;
  border-top: 2px solid lightgray;
}
#navbar {
  position: fixed;
  background: white;
  z-index: 10001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}
/* @media (max-width: 768px) {
  #navbar {
    position: fixed;
    z-index: 10000;
    background: white;
    width: 100%;
    border-bottom: 1px solid lightgray;
  }
  .page_mobile_start {
    margin-top: 150px;
  }
} */
@media (min-width: 768px) and (max-width: 960px){ 
  #navbar {
    flex-direction: column;
  }
}
@media (max-width: 960px){ 
  #navbar {
    /* margin: .5em auto; */
  }
}

#navbar > * {
  display: flex;
  align-items: center;
}
#menu_search_holder {
  position: relative;
  background: lightgrey;
  line-height: 3em;
  margin: 0 2em;
  width: 40vw;
  max-width: 500px;
  border-radius: 12px;
}
#menu_search_holder .search_icon{
  position: absolute;
  top: 50%; right: 20px;
  transform: translateY(-50%);
}
#menu_search {
  width: 100%;
  background: lightgrey;
  outline: none;
  padding: 5px;
  padding-left: 30px;
}
@media (max-width: 750px){ 
  #first_block {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100vw;
  }
  #menu_search_holder {
    width: 100%;
    margin: 0;
    margin-top: 1em;
  }
}
#menu ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
}
#menu li {
  padding: 5px;
  margin: 0 10px;
}
#mobile_menu {
  display: none;
}
#mobile_menu li {
  display: block;
  padding: 1em 0;
}
.menu_icon {
  color: var(--green-kati) !important;
}
.menu_link_url {
  margin: 0;
  padding: 5px 0;
}
#mobile_menu.active {
  padding-left: 20px;
  display: block;
  width: 100%;
  background: #FAFAFA;
  box-shadow: 0px 50px 50px white;
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 50;
  height: 50vh;
  list-style-type: none;
}
.menu_items a {
  text-decoration: none;
}
.menu_items a, .menu_items a:visited {
  color: black
}
.menu_items a:hover {
  color: var(--green-kati);
}
input {
  border: 1px solid lightgrey;
  border-radius: 10px;
  line-height: 2em;
  caret-color: var(--green-kati);
  padding-left: 10px;
}
@media (max-width: 750px){
  input{
    padding: 10px 5px;
    outline: 0;
  } 
}
.get_back_btn {
  color: #bbb;
  border: 1px solid #bbb;
  margin: 0;
  width: fit-content;
  padding: 0 5px;
}
.get_back_btn:hover {
  color: var(--green-kati) !important;
  border: 1px solid var(--green-kati) !important;
}
.get_back_btn span { 
  padding: 0 10px;
}
.slide_item {
  position: relative;
  fill: red;
  height: 50vh;
}
.slide_item .overlay{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background-color: #27272760;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}
.slide_item h1 {
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255,255,255,.7);
  font-family: "Just Another Hand", cursive;
  font-weight: 700;
  font-style: normal;
  font-size: 8em;
  line-height: 1em;
}
.admin_page_title {
  /* color: rgba(57, 57, 57, 0.7); */
  color: var(--blue-kati);
  font-family: "Poppins", cursive;
  font-weight: 700;
  font-style: normal;
}
.load_more_row {
  margin-top: 1em;
}
.load_more_btn , .eventkati_btn, .btn.show {
  background: var(--green-kati);
  border: 1px solid  var(--green-kati);
  color: white;
}
.load_more_btn:hover , .eventkati_btn:hover , .btn.show:hover{
  background: white;
  border: 1px solid  var(--green-kati);
  color: var(--green-kati);
}
.load_more_btn:active , .eventkati_btn:active , .btn.show:active{
  background: white;
  border: 1px solid  var(--green-kati);
  color: var(--green-kati);
}
input[type='radio'] {
  accent-color: var(--green-kati);
}
.form_group {
  display: flex;
  flex-direction: column;
  
  padding: 1em 0;
}
.form_inline_group {
  display: flex;
  gap: 20px;
}
@media (max-width: 749px){
  .filter_box p {
    min-width: 0;
    flex: 1;
    flex-basis: 100%;
  }
  .filter_box input {
    width: 100%;
  }
}

.form_separator {
  border: 2px solid lightgrey;
  margin: 1em 0;
}
.form_pull_right_cta {
  justify-content: end;
}
.map_marker {
  width: 50px;
  height: 50px;
  background: url('./assets/icons/place.png');
  background-repeat: no-repeat;
}

.page_background {
  background : #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_content {
  width: 60%;
  margin: 2em auto;
  padding: 2em;
}
.page_content_container {
  margin-top: 3em;
}
.items_row_list {
  display:flex;
  flex-direction: row;
  gap:50px;
  flex-wrap:wrap;
  justify-content: flex-start;
}
.page_section {
  padding: var(--section-padding) !important;
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
}
.page_event_right {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  position: sticky;
  top: 12%;
}
.page_event_left {
  display: inline-block;
  width: 70%;
  margin-right: 5%;
}
@media all and (max-width: 768px) {
  .page_section {
    padding: var(--section-sm-padding) !important;
  }
  .page_event_right {
    display: block;
    width: 100%;   
    margin-top: 20px;
  }
  .page_event_left {
    display: block;
    width: 100%;    
  }
}
.inline_group {
  display:flex;
  flex-direction: row;
  gap: 50px;
}
.event_motscles {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0; padding: 0;
}
.event_motscles li {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 10px;
}
.img_event_header {
  width: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  
}

@media all and (max-width: 768px) {
  .img_event_header {
    order: 1;
    position: relative;
    width: 100%;
    height: 25vh;
    margin-top: 1em;
  }

}
:root {
  --sm: 768px;
}
.sm {
  display: none !important;
  
}
.md {
  display:initial !important;
}
@media all and (max-width: 750px) {
  .sm {
    display: initial !important;
    position: absolute;
    right: 20px;
    top: 20%;
  }
  .md {
    display: none !important;
  }
}
#footer {
  display: flex;
  flex-direction: column;
  background: #EDF3EB;
}
#footer section {
  display: flex;
  width: 100%;
}
@media all and (max-width: 768px){
  #footer section {
    flex-direction: column;
  } 
}
#footer #footer_top > * {
  flex: 1;
}
#footer_top_presentation {
  flex: 3 1 !important;
  font-size: 1.2rem;
  line-height: 1.6;
  padding-right: 50px;
}
@media all and (max-width: 768px){
 #footer #footer_top {
  gap: 50px;
 } 
  #footer_top_presentation {
    padding-right: 0;
  }
}
#footer {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  color: #7e7f7b;
}
#footer p {
  margin: 0;
}
#footer .socialMediaIcon {
  padding-right: 10px;
}
#footer_top {
  color: #7e7f7b;
}
#footer_top ul {
  margin: 0;
  padding: 0;
}
#footer_top li {
  padding-bottom: 10px;
}
#footer_top h3 {
  color: black;
  text-transform: uppercase;
  margin-bottom: .5em;
  font-size: 1.1rem;

}
#footer hr {
  border: 0;
  border-top: 5px solid var(--green-kati);
}
#footer #footer_top {

}
#footer_bottom  img {
  margin: 0 10px; 
}
#footer ul {
  list-style: none;
}
#footer #footer_bottom {
  justify-content: space-between;
}
@media (max-width: 768px){
  /* #footer #footer_bottom {
    display: none;
  }  */
}
#footer_bottom > * {
  display: flex;
  align-items: center;
}
#footer img {
  width: 50px;
  height: 50px;
}
.footer_icon {
  color: #6AB50C;
  padding-right: 10px;
}
.footer_payment_methods {
  justify-content: center;
  margin: 10px 0;
}

.payment_feedback {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5em 0;
}



/* EVENT CARD */
.event_card_image:hover {
  cursor: pointer;
}
.event_card_image {
  width: 100%;
  height: 200px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/*  */

div[role="cell"] {
  min-width: 10px !important;
}

.check-holder {
  display: flex;
  justify-content: center;
}



#event_tickets_card {
 
}
@media (max-width: 749px){  
  /* #event_tickets_card {
    flex-direction: column;
    display: flex;
  } */
}

.event_ticket_card {
  box-sizing: border-box;
  display: inline-block; 
  width: 32%;
  margin: 1em;
  margin-left: 0;
}
.event_ticket_card .ant-card-head {
  background: rgba(0, 0, 0, 0.02);
}
.event_ticket_card .ant-card-body {
  padding: 0 !important;
}
@media (max-width: 1024px){ 
  .event_ticket_card {
    width: 45%;
    box-sizing: border-box;
    margin: 1em;
    margin-left: 0;
  }
  
}
@media (max-width: 749px){ 
  .event_ticket_card {
    width: 100%;
    box-sizing: border-box;
    margin: 1em;
    margin-left: 0;
  }
  
}

.subcategory_card {
  cursor: pointer
}
.payment_method_icon {
  margin-right: 10px;
  width: 80px;
  height: auto;
  border-radius: 25px;
  border: 1px solid darkgrey;
}

.single_event_cta_block {
  display: block;
}
.single_event_cta_block > * {
  display: inline-block;
  width: 48%;
  margin: 0 1%;
  vertical-align: top;  
}
.dropdown > button {
  width: 100% !important;
}

.accordion-button:focus {
  box-shadow: inherit;
}
.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: inherit;
  box-shadow: inherit;
}

.anticon {
  color: var(--green-kati);
}

.share_button_bar {
  width: fit-content;
  background: #fff;
  padding: 10px;
  border-radius: 12px;
}
.share_button_bar p {
  font-size: .9em;
  font-style: italic;  
}
.share_button_bar > button {
  margin-right: 10px;
}

.selected_category_alert {
  width: fit-content;
  cursor: pointer;
}
.selected_category_alert:hover { 
  color: red;
}
.compostModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.country_icon_sm {
  width: 25px;
  height: 25px;
}
.home_country_list {
  display: flex;
  justify-content: center;
  background : #eeea;
  padding: 10px 0;
}
.country_list_item:hover {
  cursor:pointer;
  mix-blend-mode: normal;

}
.country_list_item.active {
  cursor:pointer;
  border: 1px solid var(--green-kati);
  color: var(--green-kati);
  mix-blend-mode: normal;
}
.country_list_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 10px;
  margin: 0 10px;
  mix-blend-mode: luminosity;
}
.event_author_bar {
  position:relative;
}
.msg_button {
  /* right: 0;
  position: absolute; */
  margin: 0 10px;
  background: white;
  border: 0;
  color: var(--blue-kati-dark);
  padding: 5px;
  border-radius: 10px;
}
.msg_button:hover {
  background: var(--blue-kati-dark);
  color: white;
}
.buy_shortcut_bar {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin: 0 10px;
  margin-bottom: 10px;
}
.buy_shortcut_ticket_name {
  font-weight: 700;
}
.buy_shortcut_input {
  width: 40px;
  border: 0;
  text-align: center;
  padding: 5px;
  background: transparent;

  -webkit-appearance: none;
  -moz-appearance: textfield;

}
.buy_shortcut_quantity_button {
  background: var(--green-kati);
  border: 0;
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  color: white;
}
.buy_shortcut_quantity_button:hover { 
  background: white;
  color: var(--green-kati);
}
.buy_shortcut_quantity_button.disabled { 
  background: lightgray;
}
td input {
  text-align: center;
}

.modal , .modal-backdrop{
  z-index: 10002;
}

/* HOME ORGANISATEUR LIST
.orga_slider .organisateurs_list {
  display:flex;
  flex-direction: row;
  gap:20px;
  flex-wrap:wrap;
  justify-content: flex-start;
  justify-items: start;
}

@media all and (max-width: 768px){
  .organisateurs_list {
    justify-content: space-around;
  }
} */
.slick-arrow::before {
  color: var(--green-kati);
}
.form_success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .orga_slider {
    width: 90%;
    margin: 0 auto;
  }
}
.slick-track {
  transform: translate3d(0px, 0px, 0px);
}
.countries_select_dropdown, .ant-select-dropdown  {
  z-index:20000;
}

.guZdik {
  min-width: 0;
}